code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input {
  color-scheme: dark;
}
.recharts-responsive-container text {
  fill: var(--chart-grid-text) !important;
  font-size: 12px;
}
.recharts-responsive-container tspan {
  fill: var(--chart-grid-text) !important;
  font-size: 12px;
}

.crumbs {
  width: 100%;
}

.crumbs ul li {
  position: relative;
  background-color: #222131;
  padding: 0.7rem 0.4rem;
  width: calc(100% - 20px);
}

.crumbs ul li:after {
  position: absolute;
  right: -20px;
  top: 0;
  bottom: 0;
  content: "";
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid var(--gray-800);
}

.crumbs ul li:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.path-bread ul {
  display: flex;
  width: fit-content;
}

.path-bread ul li {
  position: relative;
  padding: 0.5rem 1.75rem 0.5rem 1.75rem;
}

.path-bread ul li:first-child {
  padding: 0.5rem 1.75rem 0.5rem 0.75rem;
}

.path-bread ul li:after {
  position: absolute;
  right: -14px;
  top: 0;
  content: "";
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
  z-index: 1;
}

.arrow-div:after {
  position: absolute;
  right: -28px;
  top: 0;
  content: "";
  border-top: 28px solid transparent;
  border-bottom: 28px solid transparent;
  z-index: 1;
  border-left: 28px solid;
}

/*
.path-bread ul li:nth-child(1):after {
  border-left: 14px solid #701a75;
}

.path-bread ul li:nth-child(2):after {
  border-left: 14px solid #0c4a6e;
}

.path-bread ul li:nth-child(3):after {
  border-left: 14px solid #7c2d12;
}

.path-bread ul li:nth-child(4):after {
  border-left: 14px solid #1f5872;
} */

.path-bread ul li:last-child {
  background-color: #3ea142;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 0.3rem 0.7rem 0.3rem 1.5rem;
  font-size: 1rem;
}

.path-bread ul li:last-child:after {
  display: none;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #76768b !important;
}

.channel-filter.MuiInputBase-root {
  border-radius: 0.75rem !important;
}

.MuiInputLabel-root {
  color: #76768b !important;
}

.MuiFormControl-root svg {
  fill: #76768b;
  zoom: 2;
}

.drop svg {
  fill: #ffffff !important;
  zoom: 2;
}

.drop .MuiSelect-select {
  color: white !important;
  font-size: 16px;
  font-weight: bold;
}

.MuiSelect-select {
  color: white !important;
}

.MuiMenu-paper {
  background-color: #222131 !important;
  color: white !important;
}

#date-picker-id .MuiPopover-paper {
  background-color: rgb(17 24 39) !important;
  max-width: 900px;
  width: 900px;
}

.loader {
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid rgb(74 63 204); /* Blue */
  border-radius: 50%;
  width: 28px;
  height: 28px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.pagination {
  margin: 20px auto 0 auto;
  display: flex;
  list-style: none;
  justify-content: flex-end;
  outline: none;
}

.pagination > li > a {
  padding: 10px 18px;
  outline: none;
  cursor: pointer;
}

.pagination > li > a,
.pagination > li > span {
  color: white !important;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  border: 1px solid white !important;
}

.pagination a:hover {
  background-color: white;
  color: #767887 !important;
}

.pagination > .active > a:hover {
  color: #767887 !important;
}

.timezone > div {
  background-color: #39394b;
  border: none;
  border-bottom: 1px solid #767887;
  color: white;
}

.timezone > div > div > :first-child {
  color: white;
}

.timezone > div {
  box-shadow: none;
}

.timezone > div:hover {
  border-bottom: 1px solid #767887;
}

.MuiMenu-root > div > ul {
  max-height: 15rem;
}

button.fetchbutton {
  margin-top: 1em;
  color: #6254ff !important;
  border: 1px solid #6254ff;
}

button.fetchbutton:hover {
  margin-top: 1em;
  color: #4a3fcc !important;
  border: 1px solid #4a3fcc;
}

button.fetchbutton:disabled {
  margin-top: 1em;
  cursor: not-allowed;
  color: transparent !important;
  border: 1px solid #8c86cd;
}

.MuiLoadingButton-loadingIndicator {
  color: #8c86cd !important;
}

[type="checkbox"]:checked,
[type="radio"]:checked {
  border-color: inherit !important;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.big-label tspan {
  font-size: 13px !important;
}
